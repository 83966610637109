const { axiosCreate } = require('./Api')
const { checkResponse } = require('../controllers/errorHandler')

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

import store from '../state/store'

async function getItems({
  size = 12,
  index = 1,
  categoryID = null,
  search = null,
  orderBy = null,
  sortReverse = null
} = {}) {
  let warehouse = store.state.activeWarehouse

  if (warehouse === '') {
    warehouse = null
  }
  const result = await axiosCreate.get('/shop/items', {
    params: {
      size,
      index,
      search,
      category: categoryID,
      orderBy,
      sortReverse,
      warehouse
    },
    headers: { authorization: store.getters.token }
  })

  if ((await checkResponse(result)) !== null) return

  return result
}
async function getSuggestedItems({ itemID }) {
  const result = await axiosCreate.get(`/shop/items/${itemID}/suggested`)
  if ((await checkResponse(result)) !== null) return
  return result.data.Collection
}

async function getItem({ itemID, type = 'items' } = {}) {
  const result = await axiosCreate.get(`shop/${type}/${itemID}/`, {
    params: {
      contactID: store.getters.contactID,
      warehouse: store.state.activeWarehouse
    },
    headers: { authorization: store.getters.token }
  })

  if ((await checkResponse(result)) !== null) return

  return result.data
}

async function getItemStock({ itemID, startDate, endDate } = {}) {
  let DateTimeBusinessStart = null
  let DateTimeBusinessEnd = null
  let DateTimeExpectedStart = null
  let DateTimeExpectedEnd = null

  if (startDate) {
    DateTimeBusinessStart = dayjs(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    DateTimeExpectedStart = dayjs(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
  }

  if (endDate) {
    DateTimeExpectedEnd = dayjs(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    DateTimeBusinessEnd = dayjs(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
  }

  const result = await axiosCreate.get(`shop/custitems/${itemID}/stock`, {
    params: {
      DateTimeBusinessStart,
      DateTimeBusinessEnd,
      DateTimeExpectedStart,
      DateTimeExpectedEnd,
      contactID: store.getters.contactID,
      warehouse: store.state.activeWarehouse
    },

    headers: { authorization: store.getters.token }
  })

  if ((await checkResponse(result)) !== null) return

  return result.data
}

async function getItemPrice({ itemID, startDate, endDate } = {}) {
  let DateTimeBusinessStart = null
  let DateTimeBusinessEnd = null
  let DateTimeExpectedStart = null
  let DateTimeExpectedEnd = null

  if (startDate) {
    DateTimeBusinessStart = dayjs(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    DateTimeExpectedStart = dayjs(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
  }

  if (endDate) {
    DateTimeExpectedEnd = dayjs(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    DateTimeBusinessEnd = dayjs(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
  }

  const result = await axiosCreate.get(`shop/items/${itemID}/price`, {
    params: {
      DateTimeBusinessStart,
      DateTimeBusinessEnd,
      DateTimeExpectedStart,
      DateTimeExpectedEnd,
      contactID: store.getters.contactID,
      warehouse: store.state.activeWarehouse
    },

    headers: { authorization: store.getters.token }
  })

  if ((await checkResponse(result)) !== null) return

  return result.data
}

module.exports = {
  getItems,
  getItem,
  getSuggestedItems,
  getItemStock,
  getItemPrice
}
