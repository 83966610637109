<template>
  <b-row v-if="suggestedItems.length > 0" class="pb-5">
    <b-col cols="12" class="pt-3 pb-0 mb-0">
      <hr />
      <h3 class="mb-0">{{ $t('related-items') }}:</h3>
    </b-col>
    <b-col
      v-for="suggestedItem in suggestedItems"
      :key="suggestedItem.ID"
      class="mt-0 pt-0"
      xs="12"
      sm="6"
      lg="4"
      xl="3"
    >
      <item :item="suggestedItem" :suggested-item="true" />
    </b-col>
  </b-row>
</template>

<script>
import item from '@/components/items/Item'
export default {
  components: {
    item
  },
  props: {
    suggestedItems: {
      required: false,
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style></style>
