<template>
  <div>
    <p>
      {{ this.$t('no-warehouse-choise-made') }}
    </p>
    <warehouse-selector-form />
  </div>
</template>

<script>
import WarehouseSelectorForm from '@/components/global/WarehouseSelectorForm'
export default {
  components: {
    WarehouseSelectorForm
  }
}
</script>

<style></style>
