<template>
  <div class="item-planner-single pt-2">
    <h5 v-if="warehouseItemTitleInfo">{{ warehouseItemTitleInfo }}</h5>
    <h5
      v-if="warehouseItemTitleError"
      v-html="warehouseItemTitleError"
      class="text-danger"
    />
    <p v-if="warehouseItemInfo" v-html="warehouseItemInfo" />
    <div v-if="showAddToCartComponent">
      <b-row>
        <b-col cols="12" class="amount pt-1 pb-2">
          <div class="form-group amount">
            <b-button
              :disabled="addToCartLoading"
              @click="
                changeAmount({
                  amount: amount - 1,
                  maxStock: warehouse.MaxPerCustomerLeft || 0
                })
              "
              size="sm"
              variant="secondary"
            >
              <font-awesome-icon :icon="['fa', 'minus']" far />
            </b-button>

            <span class="pl-2 pr-2">{{ amount }}</span>

            <b-button
              :disabled="
                addToCartLoading ||
                  warehouse.MaxPerCustomerLeft <= this.item.Amount
              "
              @click="
                changeAmount({
                  amount: amount + 1,
                  maxStock: warehouse.MaxPerCustomerLeft || 99
                })
              "
              size="sm"
              variant="secondary"
            >
              <font-awesome-icon :icon="['fa', 'plus']" far />
            </b-button>
            <span
              v-if="
                amount > 0 &&
                  itemType != 'Package' &&
                  warehouse.MaxPerCustomer &&
                  amount > 0 &&
                  (dateEditable || activeWarehouse == warehouse.WarehouseID)
              "
              class="mt-3"
            >
              <item-planner-price
                v-if="startDate && endDate && activeWarehouse"
                :warehouse="activeWarehouse"
                :startdate="startDate"
                :enddate="endDate"
                :amount="amount"
                :itemid="item.ID"
              />
            </span>
          </div>
        </b-col>
        <b-col cols="12">
          <b-button
            v-if="
              warehouse.MaxPerCustomer &&
                itemType != 'Package' &&
                amount > 0 &&
                (dateEditable || activeWarehouse == warehouse.WarehouseID)
            "
            :disabled="addToCartLoading || warehouse.MaxPerCustomerLeft === 0"
            @click="addToCart(warehouse.WarehouseID)"
            variant="primary"
            block
          >
            <span v-if="!addToCartLoading">{{ $t('item.addToCart') }}</span>
            <font-awesome-icon
              v-if="addToCartLoading"
              :icon="['fad', 'spinner']"
              class="fa-spin"
              far
            />
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

import { addToCart, getCart } from '@/services/CartService'
import ItemPlannerPrice from './ItemPlannerPrice.vue'

dayjs.extend(isSameOrAfter)
dayjs.extend(customParseFormat)

export default {
  components: {
    ItemPlannerPrice
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: function() {
        return null
      }
    },
    startDate: {
      type: String,
      required: true,
      default: function() {
        return null
      }
    },
    endDate: {
      type: String,
      required: true,
      default: function() {
        return null
      }
    },
    dateEditable: {
      type: Boolean,
      required: true,
      default: function() {
        return null
      }
    },
    itemType: {
      type: String,
      required: true,
      default: function() {
        return null
      }
    },
    itemData: {
      type: Object,
      required: true,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      addToCartLoading: false,
      amount: 1
    }
  },
  computed: {
    showAddToCartComponent: function() {
      return (
        this.stock &&
        this.warehouse.MaxPerCustomer &&
        this.isOrderedAheadOfRequiredTime &&
        this.fitsInRentalDuration &&
        this.warehouse.MaxPerCustomerLeft > 0 &&
        this.$store.getters.amountOfBikesLeft > 0 &&
        (this.dateEditable || this.activeWarehouse == this.warehouseID)
      )
    },
    selectedWarehouse: function() {
      return this.$store.state.activeWarehouse
    },
    activeWarehouse: function() {
      return this.selectedWarehouse
    },
    loggedIn: function() {
      const user = this.$store.getters.user
      if (user.UserName) return true
      return false
    },
    stock: function() {
      return this.warehouse?.HasStock ?? false
    },
    warehouseID: function() {
      return this.warehouse.WarehouseID
    },
    warehouseItemTitleError: function() {
      if (!this.warehouse) return ''
      //if (!this.warehouse?.OpenOnStartDate || !this.warehouse.OpenOnEndDate)
      //return this.$t('location-closed-on-dates')
      if (!this.dateEditable && this.activeWarehouse != this.warehouseID)
        return `${this.$t('other-items-different-warehouse')} ${
          this.warehouseID
        }`
      if (
        (!this.stock || this.warehouse.MaxPerCustomerLeft < 1) &&
        (this.dateEditable || this.activeWarehouse == this.warehouseID)
      ) {
        if (this.stock && this.warehouse.MaxPerCustomerLeft === 0)
          return this.$t('reached-max-cart')

        if (!this.stock)
          return `${this.$t('product-unavailable')} ${this.warehouseID}`
      }

      if (this.fitsInRentalDuration === false) {
        return this.$t('maximum-reservation-days-exceeded', {
          days: this.item.MaxReservationDays
        })
      }

      if (this.isOrderedAheadOfRequiredTime === false) {
        return this.$t('item-needs-to-be-ordered-in-advance', {
          days: this.itemData.CustomFields?.CUST_MinFutureCartDays
        })
      }

      if (this.$store.getters.amountOfBikesLeft < 1) {
        return this.$t('max-bikes-reached')
      }

      return null
    },
    warehouseItemTitleInfo: function() {
      if (!this.warehouse) return ''

      if (
        this.warehouse?.MaxPerCustomer &&
        this.stock &&
        this.isOrderedAheadOfRequiredTime &&
        this.warehouse.MaxPerCustomerLeft > 0 &&
        this.fitsInRentalDuration &&
        this.$store.getters.amountOfBikesLeft > 0 &&
        (this.dateEditable || this.activeWarehouse == this.warehouseID)
      )
        return `${this.$t('available-in')} ${this.$t(this.warehouseID)}`

      return null
    },
    warehouseItemInfo: function() {
      if (!this.stock && this.warehouse?.NextStock === null)
        return this.$t('select-different-location-top-right')

      if (!this.warehouse === 'undefined') return ''
      if (
        this.warehouse.MaxPerCustomerLeft === 0 &&
        this.warehouse.MaxPerCustomer === 0
      )
        return ''

      if (this.$store.getters.amountOfBikesLeft < 1) return ''
      if (!this.warehouse) return ''
      if (this.warehouse.NextStartDate)
        return `${this.$t('is-available-from')} ${dayjs(
          this.warehouse.NextStartDate
        ).format('DD/MM/YYYY')}`

      if (this.warehouse.NextEndDate)
        return `${this.$t('is-avialable-from-return-date')} ${dayjs(
          this.warehouse.NextEndDate
        ).format('DD/MM/YYYY')}`

      if (
        this.warehouse.MaxPerCustomer &&
        this.stock &&
        this.fitsInRentalDuration &&
        this.isOrderedAheadOfRequiredTime &&
        (this.dateEditable || this.activeWarehouse == this.warehouseID)
      )
        return `${
          this.warehouse.MaxPerCustomerLeft
            ? Math.round(this.warehouse.MaxPerCustomerLeft)
            : '0'
        } ${this.$t('units-in-stock')}.`
      if (!this.dateEditable && this.activeWarehouse != this.warehouseID)
        return `${this.$t('change-your-location-in-the')} <a href="${
          this.$i18n.locale
        }/winkelmandje">${this.$t('cart')}</a>`

      if (this.warehouse.NextStock && this.stock === false)
        return `${this.$t('available-from')} ${dayjs(
          this.warehouse.NextStock
        ).format('DD/MM/YYYY')}`

      return null
    },
    fitsInRentalDuration: function() {
      if (!this.item.MaxReservationDays) {
        return true
      }
      const diff = dayjs(this.endDate, 'DD/MM/YYYY').diff(
        dayjs(this.startDate, 'DD/MM/YYYY'),
        'days',
        true
      )
      return diff < this.item.MaxReservationDays
    },
    warehouse: function() {
      if (this.item.Warehouses) {
        return this.item.Warehouses.filter(obj => {
          return obj.WarehouseID === this.selectedWarehouse
        })[0]
      } else {
        return {}
      }
    },
    isOrderedAheadOfRequiredTime: function() {
      if (this.itemData?.CustomFields?.CUST_MinFutureCartDays === null)
        return true
      if (
        dayjs(this.startDate, 'DD/MM/YYYY').isSameOrAfter(
          dayjs().add(
            this.itemData.CustomFields?.CUST_MinFutureCartDays,
            'days'
          )
        )
      ) {
        return true
      }
      return false
    }
  },
  watch: {
    warehouse: async function() {
      if (this.warehouse?.MaxPerCustomer > 0) {
        this.amount = 1
      } else {
        this.amount = 0
      }
      this.item.Amount = this.amount
      this.$store.commit('addItemStoreItem', {
        key: `${this.item.ID}-${this.selectedWarehouse}`,
        item: this.item
      })
    },
    amount: function() {
      this.item.Amount = this.amount
      this.$store.commit('addItemStoreItem', {
        key: `${this.item.ID}-${this.selectedWarehouse}`,
        item: this.item
      })
    }
  },
  created: function() {
    if (
      this.stock &&
      this.warehouse.MaxPerCustomer > 0 &&
      this.item.Amount > 0
    ) {
      this.amount = this.item.Amount
    }
    this.$store.commit('addItemStoreItem', {
      key: `${this.item.ID}-${this.selectedWarehouse}`,
      item: this.item
    })
  },
  methods: {
    changeAmount: function({ amount, maxStock }) {
      if (amount > 0) {
        this.amount = Math.min(amount, maxStock)
      }

      this.item.Amount = this.amount
      this.$store.commit('addItemStoreItem', {
        key: `${this.item.ID}-${this.selectedWarehouse}`,
        item: this.item
      })
    },

    addToCart: async function(warehouseID, amount) {
      this.addToCartLoading = true
      let itemID = null
      let compositionID = null

      if (!amount) {
        amount = this.amount
      }

      if (this.item.Type == 'Composition') {
        compositionID = this.item.ID
      } else {
        itemID = this.item.ID
      }

      const result = await addToCart({
        itemID,
        compositionID,
        dateTimeBusinessStart: this.startDate,
        dateTimeBusinessEnd: this.endDate,
        warehouseID: warehouseID,
        amount: amount
      })

      if (result != undefined) {
        this.$toasted.show(
          `${this.$t('product')} ${this.itemData.Description} ${this.$t(
            'added-to-your-cart'
          )}`
        )
      }

      this.$emit('updateItemData')
      this.amount = 1
      await getCart()

      this.addToCartLoading = false
    }
  }
}
</script>

<style scoped lang="scss"></style>
